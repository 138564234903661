<!--
 * @Descripttion: 
 * @version: 
 * @Author: alex.yang
 * @Date: 2024-07-09 16:39:02
 * @LastEditors: alex.yang
 * @LastEditTime: 2024-09-30 09:14:33
-->
<template>
    <div class="setupDocument">
        <div class="sm-title" ref="myElement">
            <div class="mt-title">门店列表</div>
        </div>
        <div class="conetnt-wrap" v-loading="loading">
            <div class="ud-title-screen">
                <div class="c-search">
                    <div><i class="el-icon-search"></i></div>
                    <el-input v-model="keyword" placeholder="输入手机号,公司名称,门店名称,编号或地址搜索"></el-input>
                </div>
                <div class="c-btn" @click="clickSearch">搜索</div>
            </div>
            <el-table
                :data="tableData"
                border
                style="width: 100%;"
                :height="'calc(100vh - ' + height + ')'"
                >
                <el-table-column prop="id" label="id" width="100" align="center"></el-table-column>
                <el-table-column prop="name" label="门店名称" align="center"></el-table-column>
                <el-table-column prop="number" label="门店编号" align="center"></el-table-column>
                <el-table-column prop="address" label="门店地址" show-overflow-tooltip></el-table-column>
                <el-table-column label="商户编号" align="center">
                    <template slot-scope="scope">
                        <div>{{scope.row.cp_mid?scope.row.cp_mid:''}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="商户门店编号" align="center">
                    <template slot-scope="scope">
                        <div>{{scope.row.cp_store_id?scope.row.cp_store_id:''}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="添加时间" align="center" show-overflow-tooltip></el-table-column>
                <el-table-column label="是否禁用"  width="100" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.is_disabled == 1">是</div>
                        <div v-else>否</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="100">
                    <template slot-scope="scope">
                        <div>
                            <el-button @click="clickEdit(scope.row)" size="mini" type="warning" plain>编辑</el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-wrap">
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="page_num"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :visible.sync="isBigShow"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="600px">
            <div class="class-wrap">
                <div class="cw-title">修改门店编号</div>
                <div class="cw-list">
                    <div class="item">
                        <div class="tip flex-wc">商户编号
                            <div class="tit" v-if="merchant_name">{{merchant_name}}</div>
                        </div>
                        <el-input @input="debouncedSearch" v-model="rowInfo.cp_mid" placeholder="请输入商户编号"></el-input>
                    </div>
                    <div class="item">
                        <div class="tip">商户门店编号</div>
                        <el-input v-model="rowInfo.cp_store_id" placeholder="请输入门店编号"></el-input>
                    </div>
                </div>
                <div class="btn-wrap">
                    <div class="cw-btn quxiao" @click="clickClose">取消</div>
                    <div class="cw-btn" @click="clickSave">保存</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import pageMixin from '@/internal/pageMixin'
import _ from 'lodash';

export default {
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'shopList',
            tableData: [],
            page_num: 1,
            page_size: 20,
            total: 0,
            keyword: '',
            isBigShow: false,
            isDel: false,
            miniId: '',
            height: '',
            loading: false,
            rowInfo: {
                id: '',
                cp_mid: '',
                cp_store_id: ''
            },
            merchant_name: '',
            debouncedSearch: _.debounce(this.performSearch, 1000),
        }
    },
    mounted () {
        this.getHeight()
        this.getStoreList()
    },
    methods: {
        // 搜索
        performSearch() {
            api.post('/service/store/get_merchant_info', {cp_mid: this.rowInfo.cp_mid}, (res) =>  {
                this.merchant_name = res.data.merchant_name?res.data.merchant_name:''
            });
        },
        // 获取高度
        getHeight() {
            const height = this.$refs.myElement.clientHeight + 230;
            this.height = height + 'px'
        },
        // 确定编辑
        clickSave(){
            if(this.rowInfo.cp_mid == ''){
                this.$message.error('请输入商户编号')
                return
            }
            if(this.rowInfo.cp_store_id == ''){
                this.$message.error('请输入门店编号')
                return
            }
            this.isBigShow = false
            let params = {
                id: this.rowInfo.id,
                cp_mid: this.rowInfo.cp_mid,
                cp_store_id: this.rowInfo.cp_store_id,
            }
            api.post('/service/store/set_store_number', params, (res) =>  {
                this.$message.success('已修改')
                this.rowInfo.cp_mid = '';
                this.rowInfo.cp_store_id = '';
                this.page_num = 1;
                this.getStoreList()
            });
        },
        // 编辑
        clickEdit(row){
            this.rowInfo.id = row.id
            this.rowInfo.cp_store_id = row.cp_store_id
            this.rowInfo.cp_mid = row.cp_mid
            this.isBigShow = true
            api.post('/service/store/get_merchant_info', {cp_mid: row.cp_mid}, (res) =>  {
                this.merchant_name = res.data.merchant_name
            });
        },
        clickClose(){
            this.isBigShow = false
            this.rowInfo = {}
        },
        // 搜索
        clickSearch(){
            this.page_num = 1;
            this.getStoreList();
        },
        // 获取门店数据
        getStoreList(){
            let params = {
                page_num: this.page_num,
                page_size: this.page_size,
                info: this.keyword,
            }
            api.post('/service/store/get_store_list', params, (res) =>  {
                this.tableData = res.data.list
                this.total = res.data.count
            });
        },
        handleCurrentChange(val) {
            this.page_num = val;
            this.getStoreList();
        },
        handleSizeChange(val){
            this.page_size = val;
            this.getStoreList();
        },
    },
}
</script>

<style lang='scss'>
.setupDocument{
    height: calc(100vh - 60px);
    overflow: hidden;
    .sm-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;
        padding: 20px 40px;
        .mt-title{
            font-size: 24px;
            color: #101010;
        }
        .mt-add{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .add{
                width: 130px;
                height: 36px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                font-size: 14px;
                color: #0068ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
        }
    }
    .conetnt-wrap{
        padding: 0 20px;
        margin: 20px;
        background: #fff;
        .ud-title-screen{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background: #fff;
            padding: 15px 0px;
            .c-search{
                width: 400px;
                height: 40px;
                border-radius: 6px;
                border: 1px solid #ddd;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 10px;
                .el-input{
                    width: calc(100% - 20px);
                    .el-input__inner{
                        border: none;
                        outline: none;
                        padding: 10px ;
                    }
                }
            }
            .c-btn{
                width: 100px;
                height: 40px;
                border-radius: 6px;
                background: #0068ff;
                border: 1px solid #0068ff;
                color: #fff;
                font-size: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
    .page-wrap{
        width: 100%;
        z-index: 999;
        background: #fff;
        height: 60px;
        position: fixed;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .pagination-wrap{
            margin-left: 250px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            text-align: center;
            font-weight: bold;
            margin-top: -5px;
        }
        .cw-list{
            padding: 20px;
            .item{
                margin-bottom: 15px;
                .tip{
                    font-size: 15px;
                    color: #101010;
                    padding-bottom: 10px;
                }
                .tit{
                    background: #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                    color: #333;
                    margin-left: 10px;
                    padding: 2px 10px;
                }
                .avatar{
                    width: 100px;
                    height: 100px;
                    vertical-align: bottom;
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 20px;
            .cw-btn{
                width: 100px;
                height: 40px;
                border-radius: 4px;
                font-size: 15px;
                color: #fff;
                background: #0068ff;
                margin: 0 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border: 1px solid #0068ff;
            }
            .quxiao{
                background: #fff;
                color: #0068ff;
            }
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }

}

</style>
